import React from "react";
import classes from './Social.module.scss';
import Email from "../Shared/Email";
import EmailIcon from '@material-ui/icons/Email';
// import EmailIcon from '@material-ui/icons/MailOutline';
// import FacebookIcon from '@material-ui/icons/Facebook';
// const emailText=(<span aria-label="MDTC Email Link" uk-icon="icon: mail"></span>);
const emailText=(<EmailIcon />);
const social = (props) => {
// <li><Email where="socialBar" text={emailText} /></li>

  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      {props.email === "true" ? 
      <li>
        <button uk-toggle="target: #emailModal" className={classes.emailBtn} type="button">{emailText}</button>
        <div id="emailModal" className={[classes.emailModal, "uk-flex-top"].join(' ')} data-uk-modal>
          <div className={[classes.eModalBody, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
            <button className={[classes.modalClose, "uk-modal-close-default"].join(' ')} type="button" data-uk-close></button>
            <h4>Email Us</h4>
            <div className={classes.edFlex}>
              <div className={classes.emailLi}>
                <p className={classes.reg}><span className={classes.name}>Laura Thomas</span><br/><Email name="laura" where="socialEmail" /></p>
                <p className={classes.cat}>Owner</p>
              </div>
              <div className={classes.emailLi}>
                <p className={classes.reg}><span className={classes.name}>Mike Thomas</span><br/><Email name="mike" where="socialEmail" /></p>
                <p className={classes.cat}>Owner</p>
              </div>
              <div className={classes.emailLi}>
                <p className={classes.reg}><span className={classes.name}>Eddie Marsh</span><br/><Email name="emarsh" where="socialEmail" /></p>
                <p className={classes.cat}>Senior Project Manager</p>
              </div>
              <div className={classes.emailLi}>
                <p className={classes.reg}><span className={classes.name}>Matt Branch</span><br/><Email name="mbranch" where="socialEmail" />
                </p>
                <p className={classes.cat}>Business Development Manager</p>
              </div>
            </div>
          </div>
        </div>
      </li> 
      : null}
          

      <li><a href="https://www.facebook.com/mdtconstruction" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="MDTC Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://id.linkedin.com/company/md-thomas-construction-inc" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="MDTC LinkedIn Link" uk-icon="icon: linkedin"></span>
      </a></li>
    </ul>
  );
};
social.defaultProps = {
  email: "false"
};
export default social;
