import React from "react";
// import { Link } from "react-router-dom";
import classes from './ServiceText.module.scss';
// import Phone from "../../components/Shared/Phone";
// import Email from "../../components/Shared/Email";

const serviceText = (props) => {
  return (
    <div className={[classes.ServiceText, classes[props.where], classes[props.addClass]].join(' ')}>
      <p>{props.addBefore !== null ? props.addBefore : null} We are committed to high quality — from the products we use, to the services we perform. We are also committed to you. We work hard to earn your trust, and 
        achieve your goals for the project. 
        {props.addAfter !== null ? <span><br/>{props.addAfter}</span> : null} 
      </p>
    </div>
  );
}
export default serviceText;