import React from "react";
import { Helmet } from "react-helmet";
import classes from './Home.module.scss';
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
// import Parallax from '../../components/Parallax/Parallax';
import Video from "../../components/Video/Video";
import introVid from "../../assets/images/condoIntro.mp4";
import mcPhoto from '../../assets/images/homeDredge4sq.jpg';
import erPhoto from '../../assets/images/homeExterior.jpg';
import erPhotoM from '../../assets/images/homeExteriorM.jpg';
import slide2 from '../../assets/images/homeVistaBella.jpg';
import fullImage from '../../assets/images/homeIslandShores1.jpg';
import career from '../../assets/images/homeTeam.jpg';

const headline=(
  <div>
    <h2>MD Thomas Construction</h2>
    <h1>Proudly Serving the <br className={classes.new1}/>Gulf Coast of Alabama and<br className={classes.brMob} /> Western Florida Panhandle <br className={classes.new2}/><span className={classes.cat}>for More Than 20 Years</span></h1>
    <h3>Contact us today: <Phone where="homeSlide" /></h3>
  </div>
)

const home = () => {
  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Exterior Restoration & Marine Construction on the Gulf Coast | MD Thomas Construction</title>
        <meta name="description" content="MD Thomas Construction has provided expert commercial construction services along the Gulf Coast of Alabama and Florida for over 20 years." />
      </Helmet>

      {/* <Parallax addClass="homePage" image={slide2} size="laxFull" content={headline} wrap="full" tbClass="osText" contentPos="leftCenter" overlay="overTrue" /> */}
      <div className={classes.introVideoSec}>
        <Video
          vidSrc={introVid}
          vidClass="siteIntro"
          frameName="video"
          Loop={true}
          image={slide2}
          vidText={headline}
          withOver="true"
          title="MDTC exterior and dock construction in Orange Beach, AL"
        />
      </div>

      <div className={classes.homeLay}>
        <div className={[classes.fullRow, classes.hlService, classes.color2].join(' ')}>
          <p className={classes.rotitle}>Specialties</p>

          <div className={[classes.medDef, classes.bodyText].join(' ')}>
            <div className={[classes.serveItem, classes.item1].join(' ')}>
              <div className={[classes.stackImg, classes.si1].join(' ')}>
                <img src={erPhoto} alt="Exterior restoration Orange Beach, AL" className={classes.item1img} />
              </div>

              <div className={[classes.stackText, classes.st1].join(' ')}>
                <div className={classes.stInner}>
                  <h4>Exterior<br/> Restoration</h4>
                  <p>We specialize in exterior preservation and restoration. We provide services that help protect your structure, and prevent damage. </p>
                  <p>Our team is equipped to handle everything from minor repairs to substantial upgrades essential to maintaining the value, safety, and 
                  overall condition of your property. </p>
                  <div className={classes.btnRow}><Link to="/exterior-services" className={classes.mdLink2Rev}>Learn More</Link></div>
                </div>
              </div>
            </div>
            <div className={classes.clearfix}/>

            <div className={[classes.serveItem, classes.item2].join(' ')}>
              <div className={[classes.stackImg, classes.si2].join(' ')}>
                <img src={mcPhoto} alt="MDTC dredging in Gulf Shores, AL" className={classes.XXimgMobile} />
              </div>

              <div className={[classes.stackText, classes.st2].join(' ')}>
                <div className={classes.stInner}>
                  <h4>Marine<br/> Construction</h4>
                  <p>We also specialize in marine construction. From rip rap and sea walls to pilings and piers, from dredging to recovery, our experienced professionals
                    are ready to tackle your waterfront maintenance and construction. Follow the link below for our full list of marine services.</p>
                  <div className={classes.btnRow}><Link to="/marine-construction" className={classes.mdLink2}>Learn More</Link></div>
                </div>
              </div>
            </div>
          </div>

          <div className={[classes.imageRow, classes.showOg].join(' ')}>
            <div className={[classes.item, classes.item1].join(' ')}><img src={erPhotoM} alt="Exterior upgrades at Gulf Coast condo" className={classes.item1img} /></div>
            <div className={[classes.item, classes.item2].join(' ')}><img src={mcPhoto} alt="Marine dredging in West Beach Pass" className={classes.item2img} /></div>
          </div>
        </div>

        <div className={[classes.fullRow, classes.hlAbout].join(' ')}>
        <p className={classes.rotitle2}>MDTC</p>

          <div className={[classes.medDef, classes.class].join(' ')}>
            <div className={[classes.col, classes.grid34, classes.right].join(' ')}>
              <div className={classes.clInner}>
              <p>MD Thomas Construction provides reliable, unparalleled service and craftmanship to commercial clients in both public and private markets. 
              Our team is committed to achieving exceptional results and total customer satisfaction, as well as completing projects on time and within budget. </p>
            
              <div className={classes.btnRow}><Link to="/company" className={classes.mdLink2}>Learn More</Link></div>
              </div>
            </div>

            <div className={[classes.col, classes.left, classes.grid63].join(' ')}><img src={fullImage} alt="MD Thomas Construction project in Gulf Shores" className={classes.imgFw} /></div>
          </div>
        </div>

        <div className={[classes.fullRow, classes.hlCareer].join(' ')}>
          <p className={classes.rotitle}>Join&nbsp;Us</p>

          <div className={[classes.med2Def, classes.class].join(' ')}>

            <div className={[classes.stackText].join(' ')}>
              <div className={[classes.hdline].join(' ')}><p className={classes.sig}>Interested in working with us?</p></div>
              <p>We are always looking for talented, dedicated people to join our team of experts. Follow the link below to learn more about 
                partnering with MDTC.</p>
              <div className={classes.linkRow}>
                <Link to="/careers" className={classes.mdLink2Rev}>Careers</Link>
              </div>
            </div>

            <div className={[classes.stackImg].join(' ')}><img src={career} alt="Construction team on job site" className={classes.imgFw} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default home;