import React from "react";
import { Helmet } from "react-helmet";
import classes from "./Marine.module.scss";
import Phone from '../../components/Shared/Phone';
import Video from "../../components/Video/Video";
import ImageGridList from "../../components/ImageGridList/ImageGridList";
import ServiceText from "../../components/ServiceText/ServiceText";
// import marineVideo from "../../assets/images/shoreConstr.mp4";
// import still from '../../assets/images/marineStill.jpg';
import marineVideo from "../../assets/images/docks1.mp4";
import still from '../../assets/images/dockBg.png';
import dredge from "../../assets/images/service2/dredge.jpg";
import pier from "../../assets/images/service2/pier2.jpg";
import boatRamp from "../../assets/images/service2/boatLaunch.jpg";
import seawall from "../../assets/images/service2/seawall.jpg";
import riprap from "../../assets/images/service2/riprap.jpg";
import pilings from "../../assets/images/service2/pilings.jpg";
import recovery from "../../assets/images/service2/recovery.jpg";

const quoteText=(
  <div>
    <p>Read through our catalogue of options below, and contact us today for a quote or consultation.</p>
    <p><Phone where="marineVideo" /></p>
  </div>
);
const mFloat=(
  <div>
    <h4>Marine Construction</h4>
    <div className={classes.spacer4}></div>
    <p><a href="#dredging" data-uk-scroll>Dredging</a></p>
    <p><a href="#riprap" data-uk-scroll>Riprap</a></p>
    <p><a href="#piers" data-uk-scroll>Piers</a></p>
    <p><a href="#seawalls" data-uk-scroll>Seawalls</a></p>
    <p><a href="#pilings" data-uk-scroll>Pilings</a></p>
    <p><a href="#boat-ramps" data-uk-scroll>Boat Ramps</a></p>
    <p><a href="#recovery" data-uk-scroll>Recovery</a></p>
    {/* <p><a href="#remediation" data-uk-scroll>Remediation</a></p> */}
  </div>
);
const marineIgItems = [
  {id:'dredging', caption:'Dredging', subCap:'', img:[dredge], imgAlt:'', class1:'li1', addClass:''},
  {id:'riprap', caption:'Riprap', subCap:'', img:[riprap], imgAlt:'', class1:'li2', addClass:'', flow:'rev'},
  {id:'piers', caption:'Piers', subCap:'', img:[pier], imgAlt:'', class1:'li3', addClass:''},
  {id:'seawalls', caption:'Seawalls', subCap:'', img:[seawall], imgAlt:'', class1:'li4', addClass:'', flow:'rev'},
  {id:'pilings', caption:'Pilings', subCap:'', img:[pilings], imgAlt:'', class1:'li5', addClass:''},
  {id:'boat-ramps', caption:'Boat Ramps', img:[boatRamp], class1:'li6', addClass:'', flow:'rev'},
  {id:'recovery', caption:'Recovery', subCap:'', img:[recovery], imgAlt:'', class1:'li8', flow:''},
];

const marine = () => {
  return (
    <div className={classes.Marine}>
      <Helmet>
        <title>Dredging, Piers, Boat Ramps & More Marine Construction</title>
        <meta name="description" content="MD Thomas Construction helps Gulf Coast clients develop, upgrade, and protect waterfront properties. We provide dredging, seawalls, piers, boat ramps, and more." />
      </Helmet>

      <div className={[classes.kpGrid, classes.ipIntro, classes.marine].join(' ')}>
        <div className={[classes.medDef, classes.class2].join(' ')}>
          <div className={[classes.col, classes.left].join(' ')}>
            <h2 className={classes.cat}>MD Thomas Construction LLC</h2>
              <h1>We provide comprehensive, licensed <span className={classes.color1}>marine construction</span> services throughout the 
              <span className={classes.color1}> Gulf Coast of Alabama</span> &amp; <span className={classes.color1}>Western Florida Panhandle</span>
            </h1>
            <div className={classes.mobSkip}><a href="#marineList" className={classes.mdLink} data-uk-scroll>Skip to Services</a></div>
          </div>

          <div className={[classes.col, classes.right, classes.tmarginSm].join(' ')}>
            <p>Our team specializes in helping clients to develop, upgrade, and protect their waterfront structures and properties. We are highly experienced, and 
              capable of accommodating marine construction projects of all types and all sizes, including dredging and marine recovery. We serve both the public and private markets.</p>
            <ServiceText where="marine" />
          </div>
        </div>
      </div>


      <div className={[classes.fullRow, classes.XXoverLight, classes.midExt].join(' ')}>
        <div className={classes.insideN}>
          <Video
            vidSrc={marineVideo}
            vidClass="marineIntroB"
            frameName="video"
            Loop="true"
            image={still}
            vidText={quoteText}
            withOver="true"
          />
        </div>
      </div>

      <div className={[classes.fullRow, classes.marineList].join(' ')}>
        <ImageGridList where="marine" iglClass2="withFloat" wrapClass="visit" igItems={marineIgItems} floatList={mFloat} />
      </div>

    </div>
  );
}
export default marine;