import React from "react";
// import { Link } from "react-router-dom";
import classes from './Footer.module.scss';
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import NavItems from "../Header/NavItems";
// import Email from "../Shared/Email";

const footer = (props) => {
  return (
    <footer className={[classes.Footer, classes[props.FooterType], classes.kpGrid, classes.v3].join(' ')}>
      <div className={classes.tfWrap}>
        <div className={classes.topFooter}>
          <div className={classes.med2Def}>
            <p className={classes.cat}>MD Thomas Construction</p>
            <div className={classes.tfwRow}>
              <div className={[classes.col, classes.grid64, classes.left, classes.ftContact].join(' ')}>
                <p className={classes.summ}>We are a commercial construction company specializing in exterior restoration and marine construction 
                  across the Alabama Gulf Coast and Western Florida Panhandle.
                </p>
              </div>

              <div className={[classes.col, classes.grid30, classes.col2].join(' ')}>
                <div className={[classes.contact].join(' ')}>
                  <p className={classes.fullRow}>4401 Money Bayou Drive<br/> Orange Beach, AL 36561</p>
                  <div><p className={classes.il}><Phone where="footer" /></p><Social where="footer" email="true" /></div>
                </div>
              </div>

              <div className={[classes.col, classes.cred].join(' ')}>
                <p className={classes.license}>Alabama General Contractor License<br/> #39995</p>
                <p className={classes.license}>Florida General Contractor License<br/> #CGC1525385</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.btmFooter].join(' ')}>
        <div className={classes.medDef}>
          <div className={[classes.col, classes.grid80, classes.ftcol1].join(' ')}>
            <NavItems where="footer" navClass="ftMenu" addClass="not"/>
          </div>
          <div className={[classes.col, classes.grid20, classes.right].join(' ')}><p className={[classes.copy, classes.btmCopy].join(' ')}>© {(new Date().getFullYear())} MD Thomas Construction LLC. <br/>Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p></div>
        </div>
      </div>

      <div className={classes.clearfix}/>
      <div className={classes.eoe}><div className={classes.tfWrap}><p>** MDTC is an equal opportunity employer. We do not discriminate in employment on the basis of race, color, religion, sex (including pregnancy and gender identity), national origin, political affiliation, sexual orientation, marital status, disability, genetic information, age, membership in an employee organization, retaliation, parental status, military service, or other non-merit factor.</p></div></div>
    </footer>
  );
}

export default footer;