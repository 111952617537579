import React from "react";
import { Link } from "react-router-dom";
// import classes from './Header.module.scss';
// import styles from '../Footer/Footer.module.scss';
const classes = require('./Header.module.scss');
const styles = require('../Footer/Footer.module.scss');
const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);


const navItems = (props) => {
  let styleParent = classes;
  if (props.where === 'footer') {
    styleParent = styles;
  }
  return (
    <ul className={[styleParent[props.navClass], props.addClass].join(' ')}>
      <li className={[styleParent.navItem, styleParent.homeLink, styleParent.mobOnly].join(' ')}><Link to="/">Home</Link></li>
      <li className={styleParent.navItem}><Link to="/exterior-services">Exterior Services</Link></li>
      <li className={styleParent.navItem}><Link to="/marine-construction">Marine Construction</Link></li>
      <li className={[styleParent.navItem, styleParent.withDrop].join(' ')}>
        <Link to="#" className={[styleParent.parentLink].join(' ')}>Company {dropIcon}</Link>
        {/* <div className={[styleParent.custDrop, "uk-navbar-dropdown"].join(' ')}> */}
        <div className={[styleParent.custDrop, [props.where !== 'footer' ?  "uk-navbar-dropdown" : null]].join(' ')}>
          {/* <ul className="uk-nav uk-navbar-dropdown-nav"> /// [styleParent.ukNav, "uk-nav uk-navbar-dropdown-nav"].join(' ') */}
          <ul className={[props.where === 'footer' ?  styleParent.ukNav : "uk-nav uk-navbar-dropdown-nav"]}>
            <li className={styleParent.dropLi}><Link to="/company">About</Link></li>
            <li className={styleParent.dropLi}><Link to="/careers">Careers</Link></li>
          </ul>
        </div>
      </li>
      <li className={styleParent.navItem}><Link to="/contact">Contact</Link></li>      
    </ul>
  );
}
navItems.defaultProps = {
  where: "notFooter"
}
export default navItems;