import React, { Component } from "react";
import classes from "./FormPages.module.scss";

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div className={[classes.cFormWrap, classes.req].join(" ")}>
        <div className={[classes.medDef, classes.mainfWrap].join(" ")}>
          <form
            className={classes.reqForm}
            onSubmit={this.submitForm}
            action="https://formspree.io/f/moqgnbjn"
            method="POST"
          >
            <input
              type="hidden"
              name="_subject"
              id="email-subject"
              value="Job Application Request from Website"
            ></input>
            <input
              type="hidden"
              name="_cc"
              value="admin@mdthomasconstruction.com"
            />
            <div
              className={[classes.formGroup, classes.name].join(
                " "
              )}
            >
              <label className={classes.required} for="ApplicantName">
                Full Name
              </label>
              <input
                className={classes.cfield}
                type="text"
                name="Applicant Name"
                id="ApplicantName"
                required
              />
            </div>

            <div
              className={[classes.formGroup, classes.phone].join(
                " "
              )}
            >
              <label className={classes.required} for="ApplicantPhone">
                Phone
              </label>
              <input
                className={classes.cfield}
                type="telephone"
                name="Applicant Phone"
                id="ApplicantPhone"
                required
              />
            </div>
            <div
              className={[classes.formGroup, classes.email].join(
                " "
              )}
            >
              <label className={classes.required} for="ApplicantEmail">
                Email
              </label>
              <input
                className={classes.cfield}
                type="email"
                name="_replyto"
                id="ApplicantEmail"
                required
              />
            </div>


            {status === "SUCCESS" ? (
              <div className={classes.successSub}>
                <p>
                  Thank you for your interest in working with us! We have
                  received your request, and will email your application soon.
                </p>
              </div>
            ) : (
              <div className={[classes.btnRow].join(" ")}>
                <button
                  className={[classes.mdLink, classes.submit].join(" ")}
                >
                  Submit
                </button>
              </div>
            )}
            {status === "ERROR" && (
              <p className={classes.errorSub}>
                Your request contains here was an error. Please check your
                information, and try again.
              </p>
            )}

          </form>
        </div>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
