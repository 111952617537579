import React from "react";
import { Helmet } from "react-helmet";
import classes from "./About.module.scss";
import { Link } from "react-router-dom";
// import Phone from '../../components/Shared/Phone';
import ir2 from '../../assets/images/sanPerdido1.jpg';
import ir3 from '../../assets/images/pier.jpg';
import ir4 from '../../assets/images/vistaBella3.jpg';
import ir5 from '../../assets/images/pier2.jpg';
import sba from '../../assets/images/sba.png';
import chamber from '../../assets/images/chamber.png';
import exterior from '../../assets/images/abIslandShores3.jpg';
import midImg from '../../assets/images/moorings3.jpg';

const about = () => {
  return (
    <div className={classes.About}>
      <Helmet>
        <title>Commercial Construction along the Gulf Coast | MD Thomas Construction</title>
        <meta name="description" content="MD Thomas Construction specializes in exterior restoration and marine construction. We serve the Gulf Coast of AL and FL with our reliable team and superior work." />  
      </Helmet>

      <div className={[classes.kpGrid, classes.ipIntro, classes.about].join(' ')}>
        <div className={[classes.medDef, classes.abMed].join(' ')}>
          <div className={[classes.col, classes.grid67, classes.left].join(' ')}>
            <h2 className={classes.cat}>MD Thomas Construction LLC</h2>
            <h1>
            We are a commercial construction company who specializes 
            in <Link className={classes.textLink} to="/exterior-services">exterior restoration and preservation, </Link> and <Link className={classes.textLink} to="/marine-construction">marine construction</Link>. 
            We have been proudly serving the Gulf Coast with reliable team members and superior craftmanship for <span className={classes.color1}>more than 20 years</span>.
            </h1>
          </div>

          <div className={[classes.col, classes.XXgrid36, classes.grid34, classes.right].join(' ')}>
            <p>Our mission is to achieve total customer satisfaction by providing high-quality services and products. It is our goal to 
            build long-lasting relationships with clients, and ensure that your experience with our team is a positive one from conception to completion.</p>
          </div>
        </div>
      </div>


      <div className={classes.abSlideWrap}>
        <div id="abSlideshow" className={[classes.abSlideshow, "XXXuk-position-relative"].join(' ')} data-uk-slideshow="animation:fade">
          <div className={classes.XXmed2Def}>
            <ul className={[classes.slItems, "uk-slideshow-items"].join(' ')}>
              <li className={[classes.ssImage, classes.i0].join(' ')}><img src={ir5} alt="Pier construction, Orange Beach, Alabama" /></li>
              <li className={[classes.ssImage, classes.i1].join(' ')}><img src={ir2} alt="Exterior package for Gulf Shores condo" /></li>
              <li className={[classes.ssImage, classes.i2].join(' ')}><img src={ir4} alt="MDTC condo exterior restoration project" /></li>
              <li className={[classes.ssImage, classes.i3].join(' ')}><img src={ir3} alt="MDT Construction team, building pier on the Gulf" /></li>
            </ul>
          </div>
          
          <div className={[classes.thumbWrap, "uk-XXposition-bottom-centerXX XXuk-position-small"].join(' ')}>
            <ul className={[classes.thumbNav, "uk-thumbnav"].join(' ')}>
              <li uk-slideshow-item="0" className={[classes.thumbImg, classes.i0].join(' ')}><button href="#"><img src={ir5} alt="Thumbnail navigation" /></button></li>
              <li uk-slideshow-item="1" className={[classes.thumbImg, classes.i1].join(' ')}><button href="#"><img src={ir2} alt="Thumbnail navigation" /></button></li>
              <li uk-slideshow-item="2" className={[classes.thumbImg, classes.i2].join(' ')}><button href="#"><img src={ir4} alt="Thumbnail navigation" /></button></li>
              <li uk-slideshow-item="3" className={[classes.thumbImg, classes.i3].join(' ')}><button href="#"><img src={ir3} alt="Thumbnail navigation" /></button></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.textInstr}><p>Select a Photo to View</p></div>
        


      <div className={[classes.fullRow, classes.abContent, classes.ZZabBody].join(' ')}>
        <div className={[classes.medDef, classes.mdLCXX].join(' ')}>
          <div className={classes.logoCombo}>
            <div className={[classes.text, classes.two].join(' ')}>
              <p>MDTC provides commercial construction services along the Alabama Gulf Coast and Western Florida Panhandle. Our team has the resources, experience, and 
                skills to take on projects of any size, within both public and private construction markets. <b>We are a certified WOSB,</b> and offer our services to 
                government institutions and other companies who contract the government for services.
              </p>
            </div>

            <div className={[classes.ilLogo, classes.logo1].join(' ')}><img src={sba} alt="Woman Owned Small Business logo" /></div>
            <div className={[classes.ilLogo, classes.logo2].join(' ')}><img src={chamber} alt="Coastal Alabama Business Chamber logo" /></div>
          </div>

          <div className={[classes.certText2, classes.btmText].join(' ')}><p>Alabama General Contractor License <span className={classes.color1}>#39995</span> <br/><span className={classes.dot}>&middot; </span> Florida General Contractor License <span className={classes.color1}>#CGC1525385</span></p></div>        
        </div>
      </div>


      <div className={[classes.fullRow, classes.infoRow].join(' ')}>
        <div className={classes.medDef}>
          <div className={classes.irBody}>
            <div className={classes.irImg}>
              <img src={midImg} alt="Waterproofing and exterior upgrades project"  className={classes.ir1}/>
              <img src={exterior} alt="Completed exterior coatings and maintenance"  className={classes.ir2}/>
            </div>
            
            <div className={[classes.stackImg, classes.stackLeft, classes.leftHlf].join(' ')}>
              <div className={[classes.absText].join(' ')}>
                <p className={classes.sig}>More About Us</p>
                <p>MDTC is committed to every client, and every project. We work closely with each client to customize a solution that fits their needs.
                Follow one of the links below to learn more about the services we offer.
                </p>
                <Link className={[classes.mdLink2, classes.md1].join(' ')} to="/marine-construction">Marine Construction</Link>
                <Link className={[classes.mdLink2, classes.md2].join(' ')} to="/exterior-services">Exterior Services</Link>
              </div>
            </div>

            <div className={[classes.stackRight, classes.rightHlf].join(' ')}>
              <p className={classes.sig}>Work With Us</p>
              <p>We are proud of our team of talented professionals, and the results-driven work they do every day.
              Follow the link below to learn more about working with MDTC.
              </p>
              <Link className={[classes.mdLink2Rev, classes.md2, classes.md2solo].join(' ')} to="/careers">Careers</Link>
            </div>
          </div>
        </div>
        
        <div className={classes.imgMobileSec}><img src={exterior} alt="MD Thomas Construction condo upgrades"  className={classes.ir2}/></div>
      </div>
    </div>
  );
}
export default about;