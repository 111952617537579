import React from "react";
import { Helmet } from "react-helmet";
import classes from './Contact.module.scss';
// import { Link } from "react-router-dom";
import Social from "../../components/Social/Social";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import image3 from "../../assets/images/contactVistaBella2.jpg";
import image3b from "../../assets/images/contactVistaBella2b.jpg";
import stackImg from "../../assets/images/excavator.jpg";

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Commercial Contractor on the Gulf Coast | MD Thomas Construction</title>
        <meta name="description" content="Contact MD Thomas Construction today for a quote on your next construction project." />
      </Helmet>

      
      <div className={[classes.fullRow, classes.contactIntro].join(' ')}>
        <div className={classes.introImg}>
          <img src={image3} alt="Waterproofing and exterior repairs" className={classes.fwImg}/>
          <img src={image3b} alt="Exterior upgrades to beach condo building" className={classes.mobImg}/>
        </div>
        <div className={classes.cbox1}><div className={classes.innerColor}>
          <h1>MD Thomas <br/>Construction</h1>
          <h2>Contact us today for a <br/> quote or consulation!</h2>
        </div></div>

        <div className={classes.cbox2}>
          <div className={[classes.cboxRow, classes.first].join(' ')}><p className={classes.cat}>Location:</p><p>4401 Money Bayou Drive<br/> Orange Beach, AL 36561</p></div>
          
          <div className={[classes.cboxRow, classes.oemail].join(' ')}><p className={classes.cat}>Email:</p><p><Email where="contactHd" /></p></div>

          <div className={[classes.col, classes.grid50].join(' ')}>
            <p className={classes.cat}>Phone:</p><p><Phone where="contactMain" /></p>
          </div>
          <div className={[classes.col, classes.grid50, classes.right].join(' ')}>
            <p className={classes.cat}>Fax:</p><p><Phone number="2519802603" text="251.980.2603" /></p>
          </div>

          <div className={[classes.col, classes.last].join(' ')}>
            <p><span className={classes.cat}>Follow:</span><br/> <Social where="contact" /></p>
          </div>

          <div className={[classes.col, classes.cboxBtns].join(' ')}>
            <a href="https://www.google.com/maps/dir//M+D+Thomas+Construction+LLC,+Money+Bayou+Drive,+Orange+Beach,+AL/@30.2887201,-87.6564147,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a07b69a9b739d:0x57a827122cddadbc!2m2!1d-87.6213949!2d30.2886658" 
             target="_blank" 
             rel="noreferrer" 
             className={classes.mdLinkRev}>Get Directions 
             <span aria-label="MDTC Directions Link" uk-icon="icon: arrow-right"></span>
            </a>
          </div>

        </div>
      </div>


      <div className={[classes.fullRow, classes.contactBodyBg].join(' ')}>
        <p className={classes.rotitle2}>Contact&nbsp;Our&nbsp;Team</p>
  
        <div className={classes.medDef}>
          <div className={classes.cbInner}>

            <div className={[classes.col, classes.grid40XX, classes.left].join(' ')}>
              <div className={[classes.cbItem, classes.top, classes.i1].join(' ')}>
                <p>
                  <span className={classes.name}>Laura Thomas</span><br/><Phone where="contactBody" /><br/>
                  <span className={classes.email}><Email name="laura" where="contactBody" /></span>
                </p>
                <p className={classes.cat}>Owner</p>
              </div>

              <div className={[classes.cbItem, classes.top, classes.i2].join(' ')}>
                <p>
                  <span className={classes.name}>Mike Thomas</span><br/><Phone where="contactBody" /><br/>
                  <span className={classes.email}><Email name="mike" where="contactBody" /></span>
                </p>
                <p className={classes.cat}>Owner</p>
              </div>
            </div>
            
            
            <div className={classes.irImg}><img src={stackImg} alt="Waterfront rip rap installation"  className={classes.ir1}/></div>
            

            <div className={[classes.col, classes.grid40XX, classes.right].join(' ')}>
              <div className={[classes.cbItem, classes.btm, classes.i3].join(' ')}>
                <p>
                  <span className={classes.name}>Eddie Marsh</span> <br/><Phone number="2519797857" text="251.979.7857" /><br/>
                  <span className={classes.email}><Email name="emarsh" where="contactBody" /></span>
                </p>
                <p className={classes.cat}>Senior Project Manager</p>
              </div>

              <div className={[classes.cbItem, classes.btm, classes.i5].join(' ')}>
                <p>
                  <span className={classes.name}>Matt Branch</span> <br/><Phone number="2519794489" text="251.979.4489" /><br/>
                  <span className={classes.email}><Email name="mbranch" where="contactBody" /></span>
                </p>
                <p className={classes.cat}>Business Development Manager</p>
              </div>

              <div className={[classes.cbItem, classes.btm, classes.btns].join(' ')}>
                <a href="/careers" className={classes.mdLink2Rev}>Work With Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default contact;