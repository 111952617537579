import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
// import Email from "../Shared/Email";
// import Social from "../Social/Social";
import logo from "../../assets/images/mdtcLogo.png";
import logoW from "../../assets/images/mdtcLogoW.png";

class Header extends Component {
  render() {
    // let logoSrc = [logo];
    // if (this.props.HeaderType === "HomeStyle") {
    //   logoSrc = [logoW];
    // }
    return (
      <header className={[classes.Header, classes.style2, classes[this.props.HeaderType]].join(' ')}>
        {/* <div className={classes.skew} /> */}
        <div className={[classes.phoneMobile, classes.mbPhone].join(' ')}>Contact Us for a Quote: <Phone where="header" /></div>

        <div className={[classes.inside, classes.hdWrap].join(' ')}>          
          <nav className={[classes.navMm, "uk-navbar-container"].join(' ')} data-uk-navbar>
            <div className={[classes.logoBlk].join(' ')}>
              {/* <Link to="/" className={[classes.logoLink, classes.reg].join(' ')}><img src={logoSrc} alt="MDTC Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link> */}
              <Link to="/" className={[classes.logoLink, classes.reg].join(' ')}><img src={logo} alt="MDTC Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
              <Link to="/" className={[classes.logoLink, classes.white].join(' ')}><img src={logoW} alt="MDTC Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
            </div>

            <div className={classes.extraLinks}>
              <div className={[classes.elLink, classes.elContact].join(' ')}><span>Contact Us for a Quote</span><br/> <Phone where="header" /></div>
              <div className={classes.mobNav}><Drawer /></div>
            </div>

            <NavItems navClass="navBarCustom" addClass="uk-navbar-nav" />
          </nav>        
        </div>
      </header>
    );
  }
}
export default Header;