import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./Exterior.module.scss";
import Phone from '../../components/Shared/Phone';
import Tabs from "../../components/Tabs/Tabs";
import ImageGridList from "../../components/ImageGridList/ImageGridList";
import ServiceText from "../../components/ServiceText/ServiceText";

import slide1 from '../../assets/images/slIslandShores1.jpg';
import slide3 from '../../assets/images/slSanPerdido2.jpg';
import slide4 from '../../assets/images/slIslandShores2.jpg';
import slide5 from '../../assets/images/slMoorings2.jpg';

import CementWaterproof from "../../assets/images/service2/placeTall.png";
import ConcreteRepair from "../../assets/images/service2/concreteRepair.jpg";
import eifs from "../../assets/images/service2/eifs.jpg";
import ExteriorCoatings from "../../assets/images/service2/exteriorCoatings.jpg";
import Handrail from "../../assets/images/service2/handrail.jpg";
import JointSeal from "../../assets/images/service2/placeTall.png";
import MetalDoor from "../../assets/images/service2/metalDoor.jpg";
import PedestrianTraffic from "../../assets/images/service2/pedestrianTraffic.jpg";
import Rappelling from "../../assets/images/service2/placeTall.png";
import Roof from "../../assets/images/service2/roof.jpg";
import Stucco from "../../assets/images/service2/stuccoRepair.jpg";
import VehicularTraffic from "../../assets/images/service2/vehicularTraffic3.jpg";
import WindowGlaze from "../../assets/images/service2/windowGlazing.jpg";
import WindowRepair from "../../assets/images/service2/windowReplacement.jpg";

const quoteText=(
  <div>
    <p>Read through our catalogue of options below, and contact us today for a quote or consultation.</p>
    <p><Phone where="aboutVideo" /></p>
  </div>
);

const moreNav=(
<div className={[classes.med3Def, classes.textCenter, classes.custMob].join(' ')}>
  <ServiceText where="exterior" addBefore="Our typical projects range anywhere from minor improvements, upgrades, and repairs, 
  to a complete exterior overhaul. We work closely with our clients, and extend our knowledge and experience to determine the best solution for their 
  specific needs." addAfter="Select a category below to view the services we offer." />
</div>);
const quoteLi=(
  <div>
    <p>Contact us today for more information, or to get started on your next project!</p>
    <p><Phone where="extList" /></p>
  </div>
);

const wpFloat=(
  <div>
    <h4>Waterproofing Services</h4>
    <p><a href="#ptraffic-coatings" data-uk-scroll>Pedestrian Traffic Coatings</a></p>
    <p><a href="#ext-coatings" data-uk-scroll>Exterior Coatings</a></p>
    <p><a href="#vtraffic-coatings" data-uk-scroll>Vehicular Traffic Coatings</a></p>
    <p><a href="#roof-coatings" data-uk-scroll>Silicone &amp; Urethane Roof Coatings</a></p>
    <p><a href="#window-glazing" data-uk-scroll>Window Glazing</a></p>
    <p><a href="#cement-waterproof" data-uk-scroll>Cementitious Waterproofing</a></p>
    <p><a href="#expansion-joints" data-uk-scroll>Expansion Joints Sealants</a></p>
  </div>
);
const waterproofItems = [
  {id:'ptraffic-coatings', caption:'Pedestrian Traffic Coatings', subCap:'Common Walkways & Balconies', img:[PedestrianTraffic], class1: '', addClass:'li1', active:'true'},
  {id:'ext-coatings', caption:'Exterior Coatings', subCap:'Elastomeric, Acrylic, Silicone, Hydrophobic', img:[ExteriorCoatings], class1: 'withBorderRXX', addClass:'li2', flow:'rev'},
  {id:'vtraffic-coatings', caption:'Vehicular Traffic Coatings', img:[VehicularTraffic], class1: 'withBorderLXX', addClass:'li3'},
  {id:'roof-coatings', caption:'Silicone & Urethane Roof Coatings', img:[Roof], class1: '', addClass:'li4', flow:'rev'},
  {id:'window-glazing', caption:'Window Glazing', img:[WindowGlaze], class1: 'withBorderL2XX', addClass:'li5'},
  {id:'cement-waterproof', caption:'Cementitious Waterproofing', img:[CementWaterproof], class1: '', addClass:'li6', flow:'rev'},
  {id:'expansion-joints', caption:'Expansion Joints Sealants', img:[JointSeal], class1: 'solo', addClass:'liSolo'},
  {id:'quote', fullCaption:[quoteLi], class1: 'quoteItem', addClass:'liQuote'},
]

const erFloat=(
  <div>
    <h4>Exterior Restoration Services</h4>
    <p><a href="#stucco" data-uk-scroll>Stucco Texture Repair</a></p>
    <p><a href="#eifs" data-uk-scroll>EIFS Repair &amp; Replacement</a></p>
    <p><a href="#metal" data-uk-scroll>Metal &amp; Fiberglass Door Replacement</a></p>
    <p><a href="#windowRep" data-uk-scroll>Window &amp; Sliding Glass Door Replacement</a></p>
    <p><a href="#handrail" data-uk-scroll>Handrail Repair &amp; Replacement</a></p>
    <p><a href="#rappelling" data-uk-scroll>Rappelling Pressure Washing</a></p>
    <p><a href="#concrete" data-uk-scroll>Concrete Repair</a></p>
    {/* <p><a href="#leak" data-uk-scroll>Leak Detection Testing</a></p> */}
  </div>
);
const extRestItems = [
  {id:'stucco', caption:'Stucco Texture Repair', img:[Stucco], class1: 'li1b', addClass:'', active:'true'},
  {id:'eifs', caption:'EIFS Repair & Replacement', subCap:'Exterior Insulation Finishing System', img:[eifs], class1: 'li2b', addClass:'', flow:'rev'},
  {id:'metal', caption:'Metal & Fiberglass Door Replacement', img:[MetalDoor], class1: 'li3b', addClass:''},
  {id:'windowRep', caption:'Window & Sliding Glass Door Replacement', img:[WindowRepair], class1: 'li4b', addClass:'', flow:'rev'},
  {id:'handrail', caption:'Handrail Repair & Replacement', img:[Handrail], class1: 'li5b', addClass:''},
  {id:'rappelling', caption:'Rappelling Pressure Washing', img:[Rappelling], class1: 'li6b', addClass:'', flow:'rev'},
  {id:'concrete', caption:'Concrete Repair', subCap:'Partial & Full-Depth', img:[ConcreteRepair], class1: 'li7b', addClass:'longX'},
]

const tab1content=(<ImageGridList where="exterior" iglClass2="withFloat" wrapClass="visit" igItems={extRestItems} floatList={erFloat}/>);
const tab2content=(<ImageGridList where="exterior" iglClass2="withFloat" wrapClass="visit" igItems={waterproofItems} floatList={wpFloat}/>);
        

const exterior = () => {
  return (
    <div className={classes.Exterior}>
      <Helmet>
        <title>Exterior Upgrades, Repairs, & Waterproofing | MD Thomas Construction</title>
        <meta name="description" content="MDTC helps protect & transform your property. We repair stucco, EIFS, & concrete, replace doors, windows, & handrails, provide waterproofing, & so much more." />
      </Helmet>


      <div className={[classes.kpGrid, classes.ipIntro, classes.exterior].join(' ')}>
        <div className={[classes.medDef, classes.class2].join(' ')}>
          <div className={[classes.col, classes.grid38, classes.left].join(' ')}>
            <h2 className={classes.cat}>MD Thomas Construction LLC</h2>
            <h1>We provide comprehensive <span className={classes.color1}>exterior restoration</span> and <span className={classes.color1}>waterproofing</span> services.</h1>
            <div className={classes.mobSkip}><a href="#exteriorServiceList" className={classes.mdLink} data-uk-scroll>Skip to Services</a></div>
          </div>

          <div className={[classes.col, classes.grid57, classes.right, classes.tmarginSm].join(' ')}>
            <p>
              Because of our considerable experience with the Alabama Gulf Coast &amp; Western Florida Panhandle — both as a professional contracting company, and as 
              residents — we are very familiar with the harsh effects our beautiful climate can have on outdoor structures. That is why we specialize in exterior 
              preservation and restoration, with powerful options that help prevent damage to your building and outdoor areas, as well as treat and repair 
              existing issues. 
            </p>
          </div>
        </div>
      </div>


      <div className={[classes.fullRow, classes.midExt].join(' ')}>
        <div className={classes.insideN}>
          <div className={classes.extSlideWrap}>
            <div id="extSlideshow" className={[classes.extSlideshow, "XXuk-position-relative"].join(' ')} data-uk-slideshow="animation:fade;autoplay:true;">
              <div className={classes.slideText}>{quoteText}</div>
              <div className={classes.maxHeight}>
                <ul className={[classes.slItems, "uk-slideshow-items"].join(' ')}>
                  <li className={[classes.ssImage, classes.i2].join(' ')}><img src={slide3} alt="EIFS repairs, and exterior coatings" className={"uk-position-bottom"} /></li>
                  <li className={[classes.ssImage, classes.i0].join(' ')}><img src={slide1} alt="Beach condo after stucco repairs" className={"uk-position-center"} /></li>
                  <li className={[classes.ssImage, classes.i4].join(' ')}><img src={slide5} alt="Waterfront property after repairs" className={"uk-position-center"} /></li>
                  <li className={[classes.ssImage, classes.i3].join(' ')}><img src={slide4} alt="MDTC client, condo on the beach" className={"uk-position-center"} /></li>
                </ul>
              </div>          
            
              <ul className={[classes.dotNav, "uk-slideshow-nav uk-dotnav XXuk-flex-center"].join(' ')}></ul>
            </div>
          </div>
        </div>
      
        <div className={classes.extraText}>{moreNav}</div>
      </div>
  
      <div className={classes.scrollSec} id="exteriorServiceList"></div>
      <Tabs 
        where="exterior" 
        class2="withFloat" 
        navAlign="center" 
        tnavWrap="floatType" 
        tcontentWrap="floatType" 
        tab1title="Exterior Restoration"
        tab1content={tab1content}
        tab2title="Waterproofing"
        tab2content={tab2content}  
      />
      {/* addNavContent={moreNav} */}
    </div>
  );
}
export default exterior;