import React from 'react';
import classes from "./Video.module.scss";
import mobBg from "../../assets/images/phProj3.jpg";

const video = (props) => {
  return (
    <div className={[classes.videoBox, classes[props.mobClass], classes[props.vidClass]].join(' ')}>
      <div className={[classes.videoWrap, classes[props.wrapClass]].join(' ')}>
        {props.withOver === "true" ? <div className={[classes.vidOverlay, classes[props.stack]].join(' ')}><div className={[classes.vidText, classes[props.textFloat], [props.vidText ? [classes.showText] : [classes.hideText]]].join(' ')}>{props.vidText}</div></div> : null}

        <props.frameName
          src={props.vidSrc}
          frameBorder="0"
          title={props.title}
          allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
          allowFullScreen
          data-uk-responsive
          uk-video="automute:true;autoplay:inview;"
          className={[classes.fullVideo, classes[props.addClass]].join(' ')}
          loop={true}
        />

        <div className={[classes.vidStill, classes[props.mobclass]].join(' ')}><img src={props.image !== null ?  props.image : mobBg} className={classes.vidImage} alt={props.title} /></div>
      </div>
    </div>
  );
};
video.defaultProps = {
  wrapClass: "defWrap",
  mobClass: "ogSwitch",
  withOver: "false",
  frameName: "iframe",
  Loop: "false",
  addClass: "defClass",
  textFloat: "left",
  stack: "flex",
};

export default video;
