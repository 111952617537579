import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import classes from './ImageGridList.module.scss';
// import Phone from "../../components/Shared/Phone";
// import Email from "../../components/Shared/Email";


class ImageGridList extends Component {
  render() {
    const igLoop = this.props.igItems.map((igItem) =>
      <li 
        key={igItem.id} 
        id={igItem.id} 
        className={[classes.igLi, classes[igItem.class1], classes[igItem.addClass], [igItem.flow === "rev" ? [classes.rev] : [classes.reg]]].join(' ')}
      >
        {igItem.flow === "rev" ? <div className={classes.listImg}><img src={igItem.img} alt={[igItem.caption]} /></div> : null}

        {igItem.class1 === "quoteItem" ? <div className={classes.lqInner}></div> : null}
        <div className={classes.listCapt}>
          {igItem.fullCaption != null ? [igItem.fullCaption] :
            <div className={[classes.mobCaption, [igItem.subCap == null ? [classes.noCap] : [classes.withCap]]].join(' ')}>
              <p className={classes.capt}>{igItem.caption}</p>
              {igItem.subCap != null ? <p className={classes.sub}>{igItem.subCap}</p> : null}
            </div>
          }
        </div>
        {igItem.flow !== "rev" ? <div className={classes.listImg}><img src={igItem.img} alt={[igItem.caption] + " project, MD Thomas Construction"} /></div> : null}
      </li>
    );
   

    const igLoopMobile = this.props.igItems.map((igItem, index) => { 
      let classList = [classes.igAccLi];
      classList = [classes.igAccLi, [igItem.addClass ? classes[igItem.addClass] : null], [igItem.class1 ? classes[igItem.class1] : null], [index === 0 ? "uk-open" : null]].join(' ');
      
      return (
        <li 
          key={igItem.id}
          id={igItem.id} 
          className={classList}>
          <button className={[classes.accTitle, "uk-accordion-title"].join(' ')}>{igItem.caption}</button>{/*  */}
          <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
            {igItem.subCap != null ? <p className={classes.sub}>{igItem.subCap}</p> : null}
            <div className={classes.listImg}><img src={igItem.img} alt={[igItem.caption]} /></div>
          </div>
        </li>
      )
    }
    );

    return (
      <div id={[this.props.where + "List"]} className={[classes.ImageGridList, classes[this.props.iglClass2], classes[this.props.where]].join(' ')}>
        <div className={[classes.iglBody, classes[this.props.iglBodyClass]].join(' ')}>
          {this.props.iglClass2 === "withFloat" && this.props.floatList !== null ?
          <div className={classes.floatBox}>
            <div className={classes.floatList} data-uk-sticky="offset:50;">
              {this.props.floatList}
            </div>
          </div>
          : null}
          
          <div className={[classes.iglWrap, classes[this.props.wrapClass]].join(' ')}>
            <ul className={[classes.serveList, classes[this.props.ulClass2]].join(' ')}>
              {igLoop}
            </ul>


            <ul className={[classes.serveListMobile, classes[this.props.ulClass2]].join(' ')} data-uk-accordion>
              {igLoopMobile}
            </ul>




          </div>
        </div>
      </div>
    );
  }
}
ImageGridList.defaultProps = {
  wrapClass: "medDef",
  iglClass2: "noFloat"
}

export default ImageGridList;