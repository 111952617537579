import React from "react";
import classes from "./Shared.module.scss";
// {/* <a className={[classes.callLink, classes[props.where]].join(' ')} href="tel: +12519802504">{props.addText ? props.addText : null}{props.text}</a> */}

const phone = (props) => {
  return (
    <a className={[classes.callLink, classes[props.where]].join(' ')} href={"tel: +1" + props.number}>{props.addText ? props.addText : null}{props.text}</a>
  );
}
phone.defaultProps = {
  number: "2519802504",
  text: "251.980.2504"
};
export default phone;