import React from "react";
import { Helmet } from "react-helmet";
import classes from './FormPages.module.scss';
import RequestForm from "./RequestForm";

const jobApplication = () => {
  return (
    <div className={[classes.FormPages, classes.JobApp].join(' ')}>
      <Helmet>
        <title>Start Your Career with MD Thomas Construction LLC</title>
        <meta name="description" content="MD Thomas Construction has served the Gulf Coast for over 20 years. We are always looking for talented people to join our team of experts." />
      </Helmet>

      <div className={classes.fpIntro}>
        <div className={classes.med2Def}>
          <h1>We are always on the lookout for<br/> dedicated, hard-working professionals.</h1>
          <p>If you think you would be a good fit for the MDTC team, fill out the form below to request an application.</p>
        </div>
      </div>


      <div className={classes.fpBody}>
        <div className={classes.formAlign}>
          <RequestForm />
        </div>
      </div>
    </div>
  );
}
export default jobApplication;