import React from 'react';
import classes from './Header.module.scss';
// import styles from '../Footer/Footer.module.scss';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import NavItems from "./NavItems";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Social from "../Social/Social";

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <NavItems navClass="SideNav" />

      <div className={classes.sideContact}>
        <div className={classes.scItem}><Phone where="drawer" /></div>
        <div className={classes.scItem}><Email where="drawer" /></div>
        <div className={classes.scItem}><Social where="drawer" /></div>
      </div>
    </div>
  );

  return (
    <div className={[classes.NavDrawer, classes.wouldBeStyles].join(' ')}>
      <Button onClick={toggleDrawer('left', true)}>
        <div className={[classes.mobileNavBtn].join(' ')}>
          <span />
          <span />
          <span />
        </div>
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.drawerRoot}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;