import React, { Component } from 'react';
import classes from "./Tabs.module.scss";

class Tabs extends Component {
  render() {
    return (
      <div className={[classes.TabSection, classes[this.props.class2], classes[this.props.where]].join(' ')} id="fix-wrap" data-uk-grid>
        <div className={[classes.wfTabHlf, classes[this.props.where]].join(' ')}>
          <div className={[classes.tabWrap, classes[this.props.tnavWrap], classes.twNav].join(' ')}>
            <ul className={[classes.tabNav, classes[this.props.navAlign]].join(' ')} data-uk-tab="connect: #projectTabs; animation:uk-animation-fade">
              <li><button><b>Click to View</b><br/><span>{this.props.tab1title}</span></button></li>
              <li><button><b>Click to View</b><br/><span>{this.props.tab2title}</span></button></li>
            </ul>

            {this.props.addNavContent !== null ? <div className={classes.addNavContent}>{this.props.addNavContent}</div> : null}
          </div>

          <div className={[classes.tabWrap, classes[this.props.tcontentWrap]].join(' ')}>
            <ul id="projectTabs" className={[classes.tabContent, "uk-switcher"].join(' ')}>
              <li className={[classes.tcBlock, classes.extc1].join(' ')}>
                {this.props.tab1content}
              </li>

              <li className={[classes.tcBlock, classes.extc2].join(' ')}>
                {this.props.tab2content}
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    );
  }
}
Tabs.defaultProps = {
  class2: "defaultTabs",
  tnavWrap: "medDef",
  tcontentWrap: "medDef",
  navAlign: "centerNav"
}

export default Tabs;