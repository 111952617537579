import React from "react";
import classes from "./Shared.module.scss";

const email = (props) => {
  return (
    <a className={[classes.Email, classes[props.where]].join(' ')} href={"mailto:" + props.name + "@mdthomasconstruction.com"}>
      {props.text !== "" ? props.text : [props.name + "@mdthomasconstruction.com"]}
    </a>
  );
}
email.defaultProps = {
  name: "office",
  text: ""
}
export default email;